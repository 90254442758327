/* Base styles */
body {
  font-family: 'Inter', sans-serif;
  background: #f4f4f6; /* Soft off-white for a clean background */
  margin: 0;
  padding: 0;
  color: #2c2c2c; /* Neutral dark text color */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

a {
  text-decoration: none;
  color: inherit;
  transition: color 0.3s;
}

a:hover {
  color: #555555; /* Medium gray for hover links */
}

button {
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  background: #3a3a3a; /* Charcoal gray for buttons */
  color: #ffffff; /* White text for contrast */
  transition: background 0.3s, transform 0.2s;
}

button:hover {
  background: #5c5c5c; /* Lighter charcoal gray on hover */
  transform: translateY(-2px);
}

/* Navigation styles */
nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #e0e0e0; /* Light gray border for subtle separation */
  background: #ffffff; /* Clean white for the navigation bar */
  position: sticky;
  top: 0;
  z-index: 10;
}

.navLinkText {
  margin: 0 15px;
  font-weight: 500;
  color: #444444; /* Neutral medium-dark gray text */
  font-size: 16px;
}

.navLinkText:hover {
  color: #3a3a3a; /* Charcoal gray hover color */
}

/* Home Component Styles */
.mainDiv {
  padding: 40px 20px;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}

.name {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #3a3a3a; /* Charcoal gray for a bold accent */
}

.my-face-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
  border: 4px solid #3a3a3a; /* Charcoal gray border */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.content {
  max-width: 600px;
  margin: 0 auto;
  line-height: 1.6;
  font-size: 1rem;
  color: #555555; /* Medium gray for body text */
}

/* Particles background */
.particles {
  position: fixed;
  z-index: -1; /* Ensures particles stay behind all content */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Project Grid Styles */
.projectGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  justify-items: center;
  align-items: start;
  margin-top: 20px;
}

.projectCard {
  background: #ffffff; /* Clean white for card backgrounds */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); /* Subtle shadow for professionalism */
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  overflow: hidden;
}

.projectCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Slightly darker shadow on hover */
}

.projectImg {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #e0e0e0; /* Light gray border */
}

.projectTitle {
  font-size: 1.1rem;
  font-weight: 600;
  color: #2c2c2c; /* Neutral dark gray for titles */
  margin: 10px 0;
  padding: 0 10px;
}

/* Popup styles */
.projectPopUps {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(44, 44, 44, 0.8); /* Semi-transparent dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.projectPopUps.active {
  opacity: 1;
  visibility: visible;
}

.popupWhiteSquare {
  background: #ffffff; /* Clean white popup */
  padding: 20px;
  border-radius: 10px;
  max-width: 400px;
  width: 90%;
  text-align: center;
  position: relative;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.xText {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: #555555; /* Medium gray for close icon */
}

.xText:hover {
  color: #3a3a3a; /* Charcoal gray hover color */
}

/* Pictures Component Styles */
.nomadic-text {
  font-size: 1.2rem;
  font-style: italic;
  margin-bottom: 30px;
  color: #555555; /* Medium gray for descriptive text */
}

/* Location button styles */
.locationButtonDiv {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 30px;
}

.locationButtonDiv button {
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  background: #e0e0e0; /* Light gray background */
  color: #2c2c2c; /* Neutral dark gray text */
  transition: all 0.3s;
}

.locationButtonDiv button.active {
  background: #3a3a3a; /* Charcoal gray for active button */
  color: #ffffff; /* White text */
}

.locationButtonDiv button:hover {
  background: #5c5c5c; /* Lighter charcoal gray hover color */
  color: #ffffff;
}

/* Pictures grid layout */
.mainPictures {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  justify-items: center;
  align-items: center;
}

/* Individual image container */
.imageDiv {
  width: 100%;
  max-width: 250px; /* Ensures pictures do not get too large */
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); /* Subtle shadow */
  transition: transform 0.3s, box-shadow 0.3s;
}

.imageDiv:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Slightly darker hover shadow */
}

/* Image styles */
.photo {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the picture is scaled without distortion */
  border-radius: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .mainPictures {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  .nomadic-text {
    font-size: 1rem;
  }

  .imageDiv {
    max-width: 200px;
  }
}
